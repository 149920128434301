import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SDKProvider } from '@tma.js/sdk-react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const mockInitData = {
  query_id: "AAHdF6IQAAAAAN0XohDhrOrc",
  user: {
    id: 123456,
    first_name: "John",
    last_name: "Doe",
    username: "johndoe",
  },
  auth_date: 1662771648,
  hash: "c501b71e775f74ce10e377dea85a7ea24ecd640b223ea86dfe453e0eaed2e2b2"
};

// We are going to mock the window.Telegram.WebApp.initData property
(window as any).Telegram = {
  WebApp: {
    initData: mockInitData,
    initDataUnsafe: mockInitData
  }
};

root.render(
  <React.StrictMode>
    <SDKProvider>
      <App />
    </SDKProvider>
  </React.StrictMode>
);

reportWebVitals();
