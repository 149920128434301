import React, { useState, useEffect } from 'react';
import './App.css';
import { useInitData } from '@tma.js/sdk-react';

const App: React.FC = () => {
  const [message, setMessage] = useState<string>('Загрузка...');
  const initData = useInitData();

  useEffect(() => {
    // Отправляем initData на сервер для валидации и получения сообщения
    const sendData = async () => {
      try {
        const response = await fetch('https://filetuka.beget.app/webhook-test/0918d9fe-9599-4fb4-9c52-7927156a3bc5', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ initData }),
        });

        const data = await response.json();
        if (data.output) {
          setMessage(data.output);
        } else {
          setMessage('Ошибка доступа');
        }
      } catch (error) {
        setMessage('Ошибка доступа');
      }
    };

    sendData();
  }, [initData]);

  return (
    <div className="App">
      <header className="App-header">
        <p>{message}</p>
      </header>
    </div>
  );
};

export default App;
